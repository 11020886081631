import React, { FC } from "react"
import { FacebookOption, Icon, Instagram, Mail } from "grommet-icons"
import { Anchor } from "grommet"

const socials: Array<[string, string, Icon]> = [
  ["Visit us on Instagram", "https://www.instagram.com/capoeiranz", Instagram],
  [
    "Visit us on Facebook",
    "https://www.facebook.com/capoeira.nz",
    FacebookOption,
  ],
  ["Email us", "mailto:capoeira@capoeira.org.nz", Mail],
]

const Socials: FC = () => (
  <>
    {socials.map(([a11yTitle, href, Icon]) => (
      <Anchor
        key={a11yTitle}
        target="_blank"
        a11yTitle={a11yTitle}
        href={href}
        icon={<Icon color="brand" size="medium" />}
      />
    ))}
  </>
)

export { Socials }
