import React from "react"
import { Anchor, Header, ResponsiveContext } from "grommet"
import { Logo } from "./logo"
import { Socials } from "./socials"
import { StaticImage } from "gatsby-plugin-image"

const PageHeader: React.FC = () => (
  <ResponsiveContext.Consumer>
    {(size): React.ReactFragment => (
      <Header direction="row" justify="between" align="center">
        <Anchor href="/" icon={<Logo />} color="black" />
        {size !== "xxsmall" && size !== "xsmall" && (
          <Anchor href="/">
            <StaticImage
              src="../../images/header_sans.png"
              alt="Capoeira Mandinga Aotearoa"
              loading="eager"
            />
          </Anchor>
        )}
        <Socials />
      </Header>
    )}
  </ResponsiveContext.Consumer>
)

export { PageHeader }
