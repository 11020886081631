import React, { FC } from "react"
import { Anchor, Box, Footer, Main, Text, Grommet } from "grommet"

import { PageHeader } from "./page-header"
import { customTheme } from "./theme"

const Layout: FC = ({ children }) => (
  <Grommet theme={customTheme} full>
    <Box align="center">
      <Box>
        <PageHeader />
        <Main pad="medium" flex overflow="auto">
          {children}
        </Main>
        <Footer background="light-4" justify="center" pad="xsmall">
          <Text textAlign="center" size="small">
            © {new Date().getFullYear()} Capoeira Mandinga Aotearoa, Built with
            {` `} <Anchor href="https://www.gatsbyjs.org">Gatsby</Anchor>
            {` and `} <Anchor href="https://v2.grommet.io">Grommet</Anchor>
          </Text>
        </Footer>
      </Box>
    </Box>
  </Grommet>
)

export default Layout
