import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo: React.FC = () => (
  <StaticImage
    src="../../images/cma-logo.png"
    alt="CMA logo"
    loading="eager"
    width={80}
    height={80}
  />
)

export { Logo }
